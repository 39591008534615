import './App.css';
import Hero from './Components/Hero';
import DemoVideo from './Components/DemoVideo';

function App() {
  return (
    <div className="App">
      <Hero />
      <DemoVideo />
    </div>
  );
}

export default App;
