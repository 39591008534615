import React from 'react';

export default function Hero() {
  return (
    <div className="bg-white">
        <div className="max-w-lg mx-auto text-center pb-16 pt-8">
            <figure>
                <img
                    className="rounded-lg text-center"
                    src="/images/superstar-icon.png"
                    alt=""
                />
            </figure>
    
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block text-yellow-500 text-5xl pb-4">Superstar</span>
                <span className="block">Respond to your reviews</span>
            </h2>
            
      <div className="mt-8 flex justify-center">
        <div className="ml-3 inline-flex">
        <a class="gumroad-button" href="https://goodsnooze.gumroad.com/l/superstar" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200">Buy license</a>
          
        </div>
      </div>      
    </div>
    </div>
  )
}
