import React, { useState, useEffect } from 'react';  
  export default function DemoVideo() {
	return (
	  <div className="relative bg-gradient-to-b py-8 sm:py-24 lg:py-32">
			<div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
			<p className="mt-2 text-2xl font-extrabold tracking-tight sm:text-4xl">
				  Walkthrough Demo
			  </p>
		  	<p className="mt-2 text-2xl tracking-tight sm:text-2xl">
		  		This quick demo walks through some of the major features of Superstar and shows you how easy it is to reply to all your App Store reviews.
		  	</p>
		  	<p className="mt-0 max-w-prose mx-auto text-xl text-white">
		  	</p>
		  	<div class="pt-6">
					<div class="aspect-w-16 aspect-h-9">
  					<iframe src="https://www.youtube.com/embed/NBnBxjMkPFE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
					</div>
		  	</div>
			</div>
	  </div>
	)
  }
